import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import * as Sentry from '@sentry/remix'
import posthog from 'posthog-js'
import { startTransition, StrictMode, useEffect } from 'react'
import { hydrateRoot } from 'react-dom/client'

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://8c2d7037654ebf7c2d86dc920059b06c@o4506402832973824.ingest.us.sentry.io/4506405260820480',
    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
      // Replay is only available in the client
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

function PosthogInit() {
  if (import.meta.env.PROD) {
    useEffect(() => {
      posthog.init('phc_FURmq4TP4Jq2dXQhzwIo6OWkxhqqJZrtQLw6gXwi6X4', {
        api_host: 'https://us.i.posthog.com',
        // api_host: '/ingest',
        // ui_host: '<ph_app_host>',
        person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
        capture_pageview: false,
      })
      const subdomain = window.location.host.split('.')[0]
      posthog.register({
        subdomain,
        organization: subdomain,
        product: 'associate',
      })
    }, [])
  }

  return null
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
      <PosthogInit />
    </StrictMode>
  )
})
